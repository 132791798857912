<template>
	<div class="page df fdc aic">
		<div class="top df">
			<div class="banner">
				<img class="selectItemImg" :style="{zIndex:index}" v-if="selectItem.image" :src="selectItem.image" />
				<a-carousel class="left" arrows dots-class="slick-dots slick-thumb">
					<a slot="customPaging" slot-scope="props">
						<img @click="index=-1" :src="productDetail.images[props.i]" />
					</a>
					<div v-for="item in productDetail.images">
						<img :src="item" />
					</div>
				</a-carousel>
			</div>
			<div class="middle">
				<div class="top df fdc jcsa">
					<div class="title">{{productDetail.product_name}}</div>
					<div style="font-size: 12px;" class="df aic">
						<span>促销价：</span>
						<span class="price"
							style="color: #D5232F;font-size: 18px;">¥{{selectItem.price||productDetail.price}}</span>
						<a-tag style="transform: translate(5px,-5px);" color="#ed868d">
							精品推荐
						</a-tag>
					</div>
				</div>
				<div class="row">
					<span style="margin: 0 26px;">运费</span>
					<span style="font-weight: bold;">重庆 至 重庆
						<a-icon type="down" /> 快递：0.00
					</span>
				</div>
				<div class="row count df aic jcsa">
					<span>月销量</span>
					<span style="color: #D5232F;">{{productDetail.sales}}</span>
					<a-divider type="vertical" />
					<span>累计评价</span>
					<span style="color: #D5232F;">27</span>
					<a-divider type="vertical" />
					<span>关注</span>
					<span style="color: #D5232F;">27</span>
				</div>
				<div class="row sku df" :key="index1" v-for="(item1,index1) in productDetail.specList">
					<div class="left">{{item1.name}}</div>
					<div class="right df fww">
						<div class="item" :class="{active:item1.active==index2}" @click="selectSku(index1,index2)"
							:key="index2" v-for="(item2,index2) in item1.item">{{item2.name}}</div>
					</div>
				</div>
				<div class="row sku df">
					<div class="left">数量</div>
					<div class="right df aic">
						<a-input-number size="large" :min="1" :max="100000" :default-value="1" @change="onChange" />
						<span
							style="color: #999;">&nbsp;&nbsp;件&nbsp;&nbsp;&nbsp;&nbsp;库存{{productDetail.stock}}件</span>
					</div>
				</div>
				<div class="row df sku">
					<div class="left"></div>
					<div class="right">
						<a-button type="danger" @click="nowBuy">
							立即购买
						</a-button>
						<a-button @click="addCart" style="margin-left: 10px;" type="danger" ghost>
							加入购物车
						</a-button>
					</div>
				</div>
				<div class="row df sku">
					<div class="left"></div>
					<div class="right">
						<a-icon type="share-alt" />
						<span>&nbsp;&nbsp;分享</span>
						&nbsp;&nbsp;&nbsp;&nbsp;
						<span @click="collect" style="cursor: pointer;">
							<a-icon :theme="productDetail.collect?'filled':'outlined'" type="star" />
							<span>&nbsp;&nbsp;收藏商品（302人气）</span>
						</span>
					</div>
				</div>
				<div class="row df sku" style="font-size: 10px;">
					<div class="left">服务承诺</div>
					<div class="right df jcsa" style="font-weight: bold;">
						<span>正品保证</span>
						<span>七天无理由退换</span>
						<span>赠运费险</span>
					</div>
				</div>
			</div>
		</div>
		<div class="bottom">
			<div class="top df por">
				<!-- <div :class="{active:tabIndex==index}" v-for="(item,index) in ['商品详情','累计评价']" @click="tabIndex=index" class="tab df jcc aic">{{item}}</div> -->
				<div :class="{active:tabIndex==index}" v-for="(item,index) in ['商品详情']" @click="tabIndex=index"
					class="tab df jcc aic">{{item}}</div>
				<div class="poa"></div>
			</div>
			<div class="bottom" v-if="tabIndex==0">
				<div v-html="productDetail.description"></div>
			</div>
			<div class="bottom" v-if="tabIndex==1">
				<div class="b-top df">
					<div class="left df fdc aic jcc">
						<span style="font-size: 28px;color: #CD0000;">90%</span>
						<span>好评度</span>
					</div>
					<div class="right df aic">
						<div class="r-right">大家印象：</div>
						<div class="l-right df fww">
							<div class="item" v-for="item in ['质量好','包装不错','做工精细','颜色好看','物流很快',]">{{item}}</div>
						</div>
					</div>
				</div>
				<div class="b-bottom">
					<div class="top df aic">
						<div class="item" :class="{active:index==0}"
							v-for="(item,index) in ['全部评价','晒图','追评','好评','中评','差评']">{{item}}(100)</div>
					</div>
					<div class="bottom">
						<div class="item df aic" v-for="item in 3">
							<div class="left df fdc aic">
								<!-- <img src="../../assets/store.png" alt=""> -->
								<div>ONLY WU</div>
							</div>
							<div class="right">
								<div class="df jcsb">
									<a-rate v-model="value" />
									<span style="color: #999;">2021-08-15</span>
								</div>
								<div class="text">是正品，质量不错，款式也不错很喜欢！</div>
								<div class="type">
									<span>款式：国风花吗</span>
									<span>尺寸：40</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// import {
	// 	bread
	// } from '@/components'
	export default {
		// components: {
		// 	bread
		// },
		data() {
			return {
				index: 999,
				value: 5,
				tabIndex: 0,
				params: {
					product_id: '',
					unique: '',
					num: 1,
				},
				productDetail: {},
				selectItem: {},
				disabled: false
			}
		},
		mounted() {
			let {
				id
			} = this.$route.query
			this.productInfo(id)
		},
		methods: {
			async addCart() {
				await this.$api.addCart(this.params)
				this.$notification.success({
					message: '添加成功',
					description: '快去购物车查看吧',
				});
			},
			selectSku(pindex, index) {
				this.index = 999
				this.$set(this.productDetail.specList[pindex], 'active', index)
				let arr = this.productDetail.specList.map(v => v.item[v.active].name)
				this.productDetail.specListValue.forEach(v => {
					if (v.key_name == arr.join('_')) this.selectItem = v
				})
				this.params.unique = this.selectItem.unique
				this.disabled = this.selectItem.num == 0 ? true : false
			},
			async nowBuy() {
				let {
					cart_id
				} = await this.$api.nowBuy(this.params)
				let {
					orderKey
				} = await this.$api.prevOrder(cart_id)
				this.$go(`/ConfirmOrder?orderKey=${orderKey}`)
			},
			async collect() {
				await this.$api.productCollect(this.productDetail.id)
				this.$ok('收藏成功')
			},
			onChange(value) {
				this.params.num = value
			},
			async productInfo(id) {
				let {
					result
				} = await this.$api.productInfo(id)
				this.productDetail = result
				this.params.product_id = result.id
				if (this.productDetail.specListValue.length > 0) {
					this.selectItem = this.productDetail.specListValue[0]
					this.params.unique = this.selectItem.unique
					let arr = this.productDetail.specListValue[0].key_name.split('_');
					arr.forEach((v1, i) => this.$set(this.productDetail.specList[i], 'active', this.productDetail
						.specList[
							i]
						.item.findIndex(v2 => v2.name == v1)))
				} else {

				}

			}
		}
	}
</script>

<style lang="scss" scoped>
	@import '@/common/scss/turn.scss';

	.ant-carousel {
		width: px2rem(401px);
		height: px2rem(482);
	}

	.ant-carousel /deep/ .slick-dots {
		height: auto;
	}

	.ant-carousel /deep/ .slick-slide img {
		border: 5px solid #fff;
		display: block;
		margin: auto;
		width: px2rem(401);
		height: px2rem(401);
	}

	.ant-carousel /deep/ .slick-thumb {
		bottom: px2rem(-65px);
	}

	.ant-carousel /deep/ .slick-thumb li {
		width: px2rem(62);
		height: px2rem(62);
	}

	.ant-carousel /deep/ .slick-thumb li img {
		width: 100%;
		height: 100%;
		filter: grayscale(100%);
	}

	.ant-carousel /deep/ .slick-thumb li.slick-active img {
		filter: grayscale(0%);
	}

	.page {
		width: 100vw;
		padding-top: px2rem(150px);

		.top {
			.banner {
				.selectItemImg {
					position: absolute;
					width: px2rem(401);
					height: px2rem(401);
				}
			}

			.middle {
				margin-left: px2rem(15px);

				.top {
					padding: 5px 0;
					width: px2rem(527px);
					height: px2rem(86px);
					background-color: rgba($color: #C8C8C8, $alpha: .2);
					padding-left: px2rem(17px);

					.title {
						font-size: px2rem(18);
					}

				}

				.row {
					margin-top: px2rem(14px);
				}

				.count {
					border-top: 1px solid #ccc;
					border-bottom: 1px solid #ccc;
					padding: px2rem(5px);
					font-size: px2rem(9);
				}

				.sku {
					margin-top: px2rem(26px);

					.left {
						width: px2rem(64);
					}

					.right {
						width: px2rem(330px);

						.item {
							cursor: pointer;
							border: 1px solid #ccc;
							padding: px2rem(1px) px2rem(15px);
							margin-right: px2rem(8px);
							margin-bottom: px2rem(8px);
						}

						.active {
							background-color: #ff7875;
							color: #fff;
						}

					}
				}
			}

			>.right {
				margin-left: px2rem(11px);
				height: px2rem(274px);
				border: 1px solid rgba($color: #ccc, $alpha: .4);

				.top {
					height: px2rem(42px);
					width: px2rem(237);
					background-color: rgba($color: #C8C8C8, $alpha: .2);
				}

				.bottom {
					padding: px2rem(13px);

					.left {
						width: px2rem(79);
						height: px2rem(79);
					}

					.right {
						margin-left: 10px;
					}
				}

				.test {
					font-size: px2rem(12px);
					padding: px2rem(13px);
				}
			}
		}

		>.bottom {
			margin-top: px2rem(55px);

			.top {
				height: px2rem(56);
				width: 100%;
				border: 1px solid rgba($color: #ccc, $alpha: .4);

				.tab {
					width: px2rem(123);
					cursor: pointer;
				}

				.active {
					border: 1px solid rgba($color: red, $alpha: .6);
					border-top: 3px solid red;
				}

				.poa {
					width: px2rem(213);
					height: 100%;
					right: 0;
					background-color: rgba($color: #CEA571, $alpha: .1);
				}
			}

			>.bottom {
				font-size: px2rem(12);
				font-weight: bold;

				.row {
					margin-left: px2rem(26);
					margin-bottom: px2rem(24);

					div {
						margin-top: px2rem(24);
						width: 33.3%;
					}
				}

				>.b-top {
					height: px2rem(142);
					width: 100%;

					.left {
						flex: 2;
					}

					.right {
						flex: 7;

						.r-right {
							white-space: nowrap;
						}

						.l-right {
							.item {
								margin-left: px2rem(58px);
								line-height: 23px;
							}
						}
					}
				}

				>.b-bottom {
					>.top {
						width: 100%;
						height: px2rem(48);
						background: rgba($color: #C8C8C8, $alpha: .2);
						border: 0;

						>.item {
							margin-left: px2rem(47);
						}

						>.active {
							font-weight: bold;
							color: #CD0000;
							border: 0;
						}
					}

					>.bottom {
						margin-bottom: px2rem(79px);

						.item {
							height: px2rem(172px);
							margin: 0 px2rem(32);
							border-bottom: 1px solid #ccc;

							>.left {
								flex: 2;

								img {
									width: px2rem(89);
									height: px2rem(89);
									border-radius: 50%;
								}

								div {
									margin-top: px2rem(10px);
								}
							}

							>.right {
								flex: 7;

								.text {
									margin-top: px2rem(13px);
									margin-bottom: px2rem(24px);
									font-weight: bold;
								}

								.type {
									color: #999;

									span {
										margin-right: px2rem(54);
									}
								}
							}
						}

					}
				}
			}
		}

	}
</style>
